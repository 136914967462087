import { Template } from '@canalplus/sdk-hodor';
import {
  ProgramGuideRubric,
  ProgramGuideTimeSlice,
} from '@dce-front/hodor-types/modules/page_container_content/electronic_program_guide/definitions';
import { memo } from 'react';
import LoadableEPGGrid from '../../../templates/EPGGrid';
import DaySelector from './DaySelector/DaySelector';

export type ElectronicProgramGuideProps = {
  isFetchingDays: boolean;
  rubrics: ProgramGuideRubric[];
  timeSlices: ProgramGuideTimeSlice[];
};

function ElectronicProgramGuide({
  isFetchingDays,
  rubrics,
  timeSlices,
}: ElectronicProgramGuideProps): JSX.Element {
  return (
    <>
      <DaySelector rubrics={rubrics} />
      <LoadableEPGGrid
        from={Template.ElectronicProgramGuide}
        isFetchingDays={isFetchingDays}
        rubrics={rubrics}
        timeSlices={timeSlices}
      />
    </>
  );
}

export default memo(ElectronicProgramGuide);
