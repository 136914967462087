import { EPGReducerAction, EPGReducerActionType } from './action';

export type EPGReducerState = {
  activeDayIndex: number;
  isFetchingEPGGrid: boolean;
  isFetchingTimeslices: boolean;
  selectedDayUrl: string;
};

const epgReducer = (
  state: EPGReducerState,
  { type, payload }: EPGReducerAction
): EPGReducerState => {
  switch (type) {
    case EPGReducerActionType.UpdateActiveDayIndex:
      return {
        ...state,
        activeDayIndex: payload.activeDayIndex,
      };

    case EPGReducerActionType.UpdateIsFetchingEPGGrid:
      return {
        ...state,
        isFetchingEPGGrid: payload.isFetchingEPGGrid,
      };

    case EPGReducerActionType.UpdateIsFetchingTimeslices:
      return {
        ...state,
        isFetchingTimeslices: payload.isFetchingTimeslices,
      };

    case EPGReducerActionType.UpdateSelectedDayUrl:
      return {
        ...state,
        selectedDayUrl: payload.selectedDayUrl,
      };

    default:
      return state;
  }
};

export default epgReducer;
